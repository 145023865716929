var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrap-com-btn f-c-c",class:{
    'type-primary': _vm.type === 'primary',
    'type-light': _vm.type === 'light',
    plain: _vm.plain,
    empty: _vm.empty,
    fs14: _vm.fs14,
  },style:(_vm.useStyle
      ? {
          width: _vm.w + 'px',
          height: _vm.h + 'px',
        }
      : {}),on:{"click":_vm.click}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }