<template>
  <div class="wrap-header">
    <div class="notice">
      <div class="layout-content f-c-b notice-content">
        <div class="left f-c">
          <img class="icon-msg" src="@/assets/img/msg.png" alt="" />
          通知
        </div>
        <div class="middle f-c ellipsis">
          <div class="point" />
          我司拟定于10月1日 8:00-18:0
        </div>
        <img class="icon-close" src="@/assets/img/close.png" alt="" />
      </div>
    </div>

    <div class="bar layout-content f-c-b">
      <div class="left f-c-c">
        您好，欢迎光临一启铖工品！
        <template v-if="token">
          <span @click="goPath('/user/info')" class="text-active login">
            {{ user.name }}
          </span>
          <div class="line">｜</div>
          <span @click="loginOut" class="text-active"> 退出登录 </span>
        </template>
        <template v-else>
          <span @click="goPath('/login')" class="text-active login">登录</span>
          <div class="line">｜</div>
          <span @click="goPath('/register')" class="text-active">注册</span>
        </template>
      </div>
      <div class="right f-c-c">
        <el-dropdown>
          <span class="my">
            我的一启铖<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>黄金糕</el-dropdown-item>
            <el-dropdown-item>狮子头</el-dropdown-item>
            <el-dropdown-item>螺蛳粉</el-dropdown-item>
            <el-dropdown-item disabled>双皮奶</el-dropdown-item>
            <el-dropdown-item divided>蚵仔煎</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <div class="line">｜</div>
        关于我们
        <div class="line">｜</div>
        400-999-9999
      </div>
    </div>

    <div class="layout-content search f-c">
      <img
        @click="goIndex"
        class="img-logo"
        src="@/assets/img/logo.png"
        alt=""
      />
      <div class="middle">
        <div class="item-input">
          <div class="input-wrap f-c-c">
            <input
              class="inp"
              type="text"
              v-model="keyword"
              placeholder="请输入产品名称/品牌/型号"
            />
            <com-btn @click="goList" class="btn-search">搜索</com-btn>
          </div>
          <div class="recommend">
            推荐词:
            <span class="item" v-for="item in recommendList" :key="item">
              {{ item }}
            </span>
          </div>
        </div>
        <com-btn @click="goNeedPath('Quote')" type="light" class="btn-quick">
          快速报价
        </com-btn>

        <el-badge :max="99" type="primary" :value="cartNum" class="item">
          <com-btn
            id="cart-num"
            @click="goNeedPath('Cart')"
            plain
            class="btn-cart"
          >
            购物车
          </com-btn>
        </el-badge>
      </div>
      <div class="right">
        <img class="img-qr" src="@/assets/img/index-qr.png" alt="" />
        企业微信 专人对接
      </div>
    </div>

    <div class="layout-content cate-bar f-c">
      <div class="cate-menu f-c-c">
        <img class="icon-cate" src="@/assets/img/cate.png" alt="" />
        全部商品分类
        <div class="cate-box f-c">
          <div class="left">
            <div v-for="item in menuList" :key="item.name" class="item">
              <div class="title">{{ item.name }}</div>
              <div class="subtitle">{{ item.name }}</div>
            </div>
            <div class="right">
              <div class="title">全部商品分类</div>
              <div class="list">
                <div
                  v-for="item in menuList"
                  :key="item.name"
                  class="sub-item f-c"
                >
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-for="item in menuList" :key="item.name" class="menu-item f-c-c">
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
import { indexData } from "@/api/index";
import { getToken } from "@/utils/cookies";
export default {
  components: {},
  computed: {
    cartNum() {
      return this.$store.state.cartNum;
    },
  },
  data() {
    return {
      token: "",
      user: {},
      recommendList: [],
      keyword: "",
      menuList: [
        {
          name: "品牌选型",
          path: "",
        },
        {
          name: "案例库",
          path: "",
        },
        {
          name: "电子书",
          path: "",
        },
        {
          name: "供应商合作",
          path: "",
        },
      ],
    };
  },
  mounted() {
    this.getRecommendList();
    this.token = getToken();
  },
  methods: {
    getRecommendList() {
      indexData({ clientType: "PC" }).then(() => {
        this.recommendList = ["把手", "拉手", "拉手1"];
      });
    },
    ifLogin() {
      this.$router.push({
        path: "/login",
      });
      return false;
    },
    goPath(path) {
      this.$router.push({
        path,
      });
    },

    loginOut(row) {
      console.log(row);
      this.$confirm("确定退出登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {});
    },
    goIndex() {
      this.$router.push({
        path: "/",
      });
    },
    goNeedPath(name) {
      // if (this.ifLogin()) return;
      this.$router.push({
        name,
      });
    },
    goList() {
      this.$router.push({
        path: "/goodsList",
        query: {
          keyword: this.keyword,
        },
      });
    },
  },
};
</script>
<style scoped lang="scss">
.wrap-header {
  border-bottom: 3px solid var(--color-primary);
  width: 100%;
  background: #fff;

  .notice {
    width: 100%;
    height: 32px;
    background: #fbd9cd;
    font-family: MicrosoftYaHeiLight;
    font-size: 12px;
    color: #370816;

    .notice-content {
      height: 100%;
      padding: 0 12px 0 20px;
      box-sizing: border-box;
    }

    .left {
      margin-right: 30px;
    }

    .middle {
      flex: 1;
    }

    .point {
      width: 3px;
      height: 3px;
      background: #370816;
      border-radius: 50%;
      margin-right: 7px;
    }

    .icon-msg {
      width: 12px;
      height: 10px;
      margin-right: 4px;
    }

    .icon-close {
      width: 14px;
      height: 14px;
      margin-right: 12px;
      cursor: pointer;
    }
  }

  .bar {
    height: 34px;
    font-size: 12px;
    color: #444444;
    padding: 0 12px 0 20px;
    box-sizing: border-box;

    .line {
      color: #dddddd;
      margin: 0 13px;
    }

    .login {
      margin-left: 9px;
    }

    .text-active {
      color: var(--color-primary);
      cursor: pointer;
    }

    .my {
      font-size: 12px;
      color: #444444;
    }
  }

  .search {
    margin-top: 14px;
    padding-left: 20px;
    .img-logo {
      height: 80px;
      width: 270px;
      cursor: pointer;
    }

    .middle {
      display: flex;
    }

    .input-wrap {
      width: 545px;
      height: 40px;
      background: #ffffff;
      border-radius: 2px;
      border: 1px solid var(--color-primary);
      box-sizing: border-box;
      overflow: hidden;
    }

    .inp {
      border: none;
      height: 40px;
      flex: 1;
      padding-left: 5px;
      &::placeholder {
        font-size: 12px;
        color: #999999;
      }
    }

    .btn-search {
      width: 93px;
      height: 40px;
    }

    .recommend {
      margin-top: 4px;
      font-size: 12px;
      color: #444444;

      .item {
        margin-left: 10px;
        cursor: pointer;
      }
    }

    .btn-quick {
      margin-left: 30px;
      margin-right: 12px;
    }

    .right {
      width: 100px;
      font-size: 12px;
      color: #444444;
      text-align: center;
      margin-left: 30px;
    }

    .img-qr {
      width: 56px;
      height: 56px;
      margin: 0 auto;
      margin-bottom: 4px;
    }
  }

  .cate-bar {
    margin-top: 14px;

    .icon-cate {
      width: 17px;
      height: 14px;
      margin-right: 7px;
    }

    .cate-menu {
      width: 210px;
      height: 42px;
      background: var(--color-primary);
      color: #fff;
      font-size: 18px;
      color: #ffffff;
      margin-right: 10px;
      cursor: pointer;
      position: relative;
      &:hover {
        .cate-box {
          visibility: visible;
        }
      }
    }

    .menu-item {
      cursor: pointer;
      width: 134px;
      height: 40px;
      font-size: 18px;
      color: #444444;
    }
  }

  .cate-box {
    position: absolute;
    height: 474px;
    border: 2px solid var(--color-primary);
    background: #ffffff;
    bottom: -479px;
    left: -2px;
    visibility: hidden;
    z-index: 99;

    .left {
      width: 210px;
      height: 474px;
      padding-top: 10px;
      position: relative;
      box-sizing: border-box;

      &:hover .right {
        display: block;
      }

      .title {
        font-size: 14px;
        color: #444444;
        line-height: 19px;
        margin-bottom: 2px;
      }

      .subtitle {
        font-size: 12px;
        color: #999999;
        line-height: 16px;
      }

      .item {
        width: 210px;
        height: 50px;
        padding-left: 20px;
        padding-top: 6px;
        box-sizing: border-box;
        cursor: pointer;

        &:hover {
          background: rgba(153, 153, 153, 0.2);
        }
      }
    }

    .right {
      right: -770px;
      top: -2px;
      display: none;
      position: absolute;
      border: 2px solid var(--color-primary);
      padding: 20px;
      box-sizing: border-box;
      width: 770px;
      height: 478px;
      background: #ffffff;
      box-shadow: -1px 0px 4px 0px rgba(153, 153, 153, 0.5);

      .title {
        font-weight: bold;
        font-size: 14px;
        color: var(--color-primary);
        line-height: 19px;
        margin-bottom: 18px;
      }

      .sub-item {
        width: 360px;
        font-size: 14px;
        color: #444444;
        line-height: 19px;
        text-align: left;
        margin-bottom: 8px;
        &:hover {
          color: var(--color-primary);
        }
      }

      .list {
        flex-wrap: wrap;
        display: flex;
      }
    }
  }
}
</style>
