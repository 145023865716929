<template>
  <div class="wrap-banner">
    <el-carousel height="474px" :interval="5000">
      <el-carousel-item v-for="item in list" :key="item.img">
        <img :src="item.img" alt="" />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import { indexData } from "@/api/index";
export default {
  components: {},
  data() {
    return {
      list: [],
    };
  },
  mounted() {
    this.getBanner();
  },
  methods: {
    getBanner() {
      indexData({ clientType: "PC" }).then((res) => {
        let data = JSON.parse(res.result.pageData).list;
        let list = data.find((item) => item.type === "carousel");
        this.list = list.options.list;
      });
    },
  },
};
</script>
<style scoped lang="scss">
.wrap-banner {
  width: 760px;
  height: 474px;
  margin: 0 10px;
  img {
    height: 474px;
  }
}
</style>
