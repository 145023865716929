<template>
  <div class="wrap-side-bar">
    <!-- <div
      @click="selectItem(item.value)"
      class="item f-c-c"
      v-for="item in list"
      :key="item.value"
    >
      {{ item.name }}
    </div> -->
    <div @click="scrollTo('recommendForYou')" class="item f-c-c">
      <div>为您</div>
      <div>推荐</div>
    </div>
    <div @click="scrollTo('recommendCate')" class="item f-c-c">
      <div>为您</div>
      <div>推荐</div>
    </div>
    <div @click="scrollTo('recommendCate')" class="item f-c-c">
      <div>问题</div>
      <div>反馈</div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      list: [
        {
          name: "为您推荐",
          value: "recommendForYou",
        },
        {
          name: "推荐",
          value: "recommendCate",
        },
      ],
    };
  },
  mounted() {},
  methods: {
    scrollTo(value) {
      this.$emit("scrollTo", value);
    },
  },
};
</script>
<style scoped lang="scss">
.wrap-side-bar {
  position: fixed;
  right: 0px;
  top: 156px;
  width: 56px;
  background: #ffffff;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  border-radius: 2px;

  .item {
    height: 55px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    font-size: 14px;
    color: #696969;
    line-height: 19px;
    cursor: pointer;
    flex-direction: column;
  }
}
</style>
