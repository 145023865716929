import { request } from "@/utils/axios";

// 获取首页楼层装修数据
export function indexData(params) {
  return request({
    url: "/buyer/other/pageData/getIndex",
    method: "get",
    needToken: false,
    params,
  });
}
