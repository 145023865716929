<template>
  <div class="layout-content wrap-brand">
    <div class="list">
      <div v-for="item in list" :key="item.title" class="item">
        <img class="img" :src="item.img" alt="" />
      </div>
    </div>
    <div @click="goBrand" class="more f-e-e">查看所有品牌 ></div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      list: [
        {
          img: require("@/assets/img/demo-brand.png"),
          title: "品牌1",
        },
        {
          img: require("@/assets/img/demo-brand.png"),
          title: "品牌",
        },
      ],
    };
  },
  mounted() {},
  methods: {
    goBrand() {
      this.$router.push({
        path: "/brand",
      });
    },
  },
};
</script>
<style scoped lang="scss">
.wrap-brand {
  // height: 232px;
  background: #fff;
  margin-top: 10px;
  padding: 30px 17px 17px 17px;
  box-sizing: border-box;

  .list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .item {
    width: 180px;
    height: 42px;
    margin: 0 7px 14px 7px;
  }

  .img {
    width: 100%;
    height: 100%;
  }

  .more {
    font-size: 12px;
    color: var(--color-primary);
    cursor: pointer;
  }
}
</style>
