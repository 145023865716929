<template>
  <div
    class="wrap-product"
    :class="{
      mg0: (index + 1) % count === 0 && index !== 0,
    }"
    @click="goDetail(item)"
  >
    <div class="top f-c-c">
      <img :src="item.img" />
    </div>
    <div class="body">
      <div class="title ellipsis2">{{ item.desc }}</div>
      <div class="price-wrap">
        <span class="symbol">¥</span>
        <span class="price">{{ item.price }}</span>
        <span class="suffix">/件</span>
      </div>
      <div class="origin-price">{{ item.price }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    index: {
      type: [String, Number],
      default: 0, //
    },
    count: {
      type: [String, Number],
      default: 5, // 一行展示的个数
    },
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    goDetail(item) {
      console.log(item);
      this.$router.push({
        path: "/goodsDetail",
        query: {
          skuId: 1387980214075654144,
          goodsId: 1376727107777855488,
        },
      });
    },
  },
};
</script>
<style scoped lang="scss">
.wrap-product {
  width: 230px;
  height: 340px;
  background: #ffffff;
  margin-bottom: 10px;
  margin-right: 12px;
  cursor: pointer;

  &.mg0 {
    margin-right: 0;
  }

  .top {
    width: 230px;
    height: 225px;
  }

  img {
    width: 154px;
    height: 154px;
    transition: transform 0.5s ease-in-out;
    &:hover {
      transform: scale(1.3);
    }
  }

  .body {
    padding: 13px;
  }

  .title {
    width: 205px;
    height: 38px;
    font-size: 14px;
    color: #444444;
    line-height: 19px;
    margin-bottom: 14px;
  }

  .price-wrap {
    height: 24px;
    font-weight: 400;
    color: var(--color-primary);

    .symbol {
      font-size: 12px;
    }
    .price {
      font-weight: 500;
      font-size: 18px;
      margin: 0 4px;
    }

    .suffix {
      font-size: 12px;
      color: #696969;
    }
  }

  .origin-price {
    font-size: 12px;
    color: #999999;
    line-height: 16px;
    text-decoration-line: line-through;
  }
}
</style>
