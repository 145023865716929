import Vue from "vue";
import Vuex from "vuex";
import { indexData } from "@/api/index";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    cartNum: 0, // 购物车数量
  },
  getters: {},
  mutations: {
    getCartNum(state) {
      indexData({ clientType: "PC" }).then((res) => {
        console.log(res);
        state.cartNum += 20;
      });
    },
  },
  actions: {
    operateCart({ commit }, product) {
      commit("getCartNum", product);
    },
  },
  modules: {},
});
