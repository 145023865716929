<template>
  <div class="wrap-fixed-bar">
    <div class="layout-content content f-c">
      <img
        @click="goIndex"
        class="img-logo"
        src="@/assets/img/logo.png"
        alt=""
      />
      <div class="all f-c-c">
        全部商品分类<i class="el-icon-arrow-down el-icon--right"></i>
        <div class="cate-box f-c">
          <div class="left">
            <div v-for="item in cateList" :key="item.name" class="item">
              <div class="title">{{ item.name }}</div>
              <div class="subtitle">{{ item.name }}</div>
            </div>
            <div class="right">
              <div class="title">全部商品分类</div>
              <div class="list">
                <div
                  v-for="item in cateList"
                  :key="item.name"
                  class="sub-item f-c"
                >
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="input-wrap f-c-c">
        <input
          class="inp"
          type="text"
          v-model="keyword"
          placeholder="请输入产品名称/品牌/型号"
        />
        <com-btn @click="goList" class="btn-search">搜索</com-btn>
      </div>

      <com-btn @click="goPath('/register')" type="light" class="btn">
        注册
      </com-btn>

      <com-btn id="cart-num" @click="goPath('/login')" plain class="btn">
        登录
      </com-btn>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      showRight: false,
      keyword: "",
      cateList: [
        {
          name: "品牌选型",
          path: "",
        },
        {
          name: "案例库",
          path: "",
        },
        {
          name: "电子书",
          path: "",
        },
        {
          name: "供应商合作",
          path: "",
        },
      ],
    };
  },
  mounted() {},
  methods: {
    goIndex() {
      this.$router.push({
        path: "/",
      });
    },
    goPath(path) {
      this.$router.push({
        path,
      });
    },
    goList() {},
  },
};
</script>
<style scoped lang="scss">
.wrap-fixed-bar {
  width: 100%;
  height: 108px;
  // padding: 14px 0;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  background: #fff;
  z-index: 99;
  border-bottom: 3px solid var(--color-primary);

  .content {
    padding-left: 38px;
  }

  .img-logo {
    width: 235px;
    height: 68px;
  }

  .all {
    position: relative;
    width: 210px;
    height: 108px;
    cursor: pointer;

    &:hover {
      .cate-box {
        visibility: visible;
      }
    }
  }

  .cate-box {
    position: absolute;
    height: 474px;
    border: 2px solid var(--color-primary);
    background: #ffffff;
    bottom: -474px;
    left: 0;
    visibility: hidden;

    .left {
      width: 210px;
      height: 474px;
      padding-top: 10px;
      position: relative;
      box-sizing: border-box;

      &:hover .right {
        display: block;
      }

      .title {
        font-size: 14px;
        color: #444444;
        line-height: 19px;
        margin-bottom: 2px;
      }

      .subtitle {
        font-size: 12px;
        color: #999999;
        line-height: 16px;
      }

      .item {
        width: 210px;
        height: 50px;
        padding-left: 20px;
        padding-top: 6px;
        box-sizing: border-box;
        cursor: pointer;

        &:hover {
          background: rgba(153, 153, 153, 0.2);
        }
      }
    }

    .right {
      right: -770px;
      top: -2px;
      display: none;
      position: absolute;
      border: 2px solid var(--color-primary);
      padding: 20px;
      box-sizing: border-box;
      width: 770px;
      height: 474px;
      background: #ffffff;
      box-shadow: -1px 0px 4px 0px rgba(153, 153, 153, 0.5);

      .title {
        font-weight: bold;
        font-size: 14px;
        color: var(--color-primary);
        line-height: 19px;
        margin-bottom: 18px;
      }

      .sub-item {
        width: 360px;
        font-size: 14px;
        color: #444444;
        line-height: 19px;
        text-align: left;
        margin-bottom: 8px;
        &:hover {
          color: var(--color-primary);
        }
      }

      .list {
        flex-wrap: wrap;
        display: flex;
      }
    }
  }

  .btn {
    margin-left: 12px;
  }

  .input-wrap {
    width: 458px;
    height: 40px;
    background: #ffffff;
    border-radius: 2px;
    border: 1px solid var(--color-primary);
    box-sizing: border-box;
    overflow: hidden;
    padding-left: 8px;
    margin-right: 18px;
  }

  .inp {
    border: none;
    height: 40px;
    flex: 1;
    padding-left: 5px;
    &::placeholder {
      font-size: 12px;
      color: #999999;
    }
  }

  .btn-search {
    width: 93px;
    height: 40px;
  }
}
</style>
