<template>
  <div class="wrap-index">
    <!-- 头部 -->
    <ComHeader />
    <div class="layout-content f-c">
      <!-- 分类 -->
      <Category />
      <!-- 轮播 -->
      <Banner />
      <!-- 信息 -->
      <InfoBox />
    </div>
    <!-- 品牌 -->
    <Brand />
    <!-- 为您推荐 -->
    <RecommendForYou ref="recommendForYou" />
    <!-- 推荐分类 -->
    <RecommendCate ref="recommendCate" />
    <!-- 固定的侧边栏 -->
    <SideBar @scrollTo="scrollRef" />
    <!-- 固定的导航 -->
    <FixedBar v-if="isShowFixedBar" />
    <div class="layout-content info-list f-c-a">
      <div class="item f-c-c">
        <img src="@/assets/img/intro-1.png" />
        正品保障
      </div>
      <div class="item f-c-c">
        <img src="@/assets/img/intro-2.png" />
        货期保证
      </div>
      <div class="item f-c-c">
        <img src="@/assets/img/intro-3.png" />
        专属客服
      </div>
      <div class="item f-c-c">
        <img src="@/assets/img/intro-4.png" />
        阳光采购
      </div>
      <div class="item f-c-c">
        <img src="@/assets/img/intro-5.png" />
        诚信服务
      </div>
    </div>
    <!-- 底部 -->
    <ComFooter />
  </div>
</template>

<script>
import Brand from "./components/Brand";
import RecommendForYou from "./components/RecommendForYou";
import RecommendCate from "./components/RecommendCate";
import Category from "./components/Category";
import Banner from "./components/Banner";
import InfoBox from "./components/InfoBox";
import SideBar from "./components/SideBar";
import FixedBar from "./components/FixedBar";
export default {
  components: {
    Brand,
    RecommendForYou,
    RecommendCate,
    Category,
    Banner,
    InfoBox,
    SideBar,
    FixedBar,
  },
  data() {
    return {
      isShowFixedBar: false, // 控制导航栏的显示
    };
  },
  mounted() {
    this.initScroll();
  },
  methods: {
    initScroll() {
      window.onscroll = () => {
        let scrollTop = document.documentElement.scrollTop;
        if (scrollTop > 200) {
          this.isShowFixedBar = true;
        } else {
          this.isShowFixedBar = false;
        }
      };
    },
    scrollRef(value) {
      let ref = this.$refs[value];
      if (ref) {
        ref.$el.scrollIntoView({
          behavior: "smooth",
        });
      }
    },
  },
};
</script>
<style scoped lang="scss">
.info-list {
  padding: 50px 0;

  img {
    height: 40px;
    width: 40px;
    font-weight: bold;
    font-size: 18px;
    color: #444444;
    line-height: 24px;
    margin-right: 8px;
  }
}
</style>
