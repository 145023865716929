/** 统一处理 Cookie */
import Cookies from "js-cookie";
let CacheKey = {
  TOKEN: "token",
};
export const getToken = () => {
  return Cookies.get(CacheKey.TOKEN);
};
export const setToken = (token) => {
  Cookies.set(CacheKey.TOKEN, token);
};
export const removeToken = () => {
  Cookies.remove(CacheKey.TOKEN);
};
