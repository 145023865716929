<template>
  <div class="wrap-recommend-cate"></div>
</template>

<script>
import { indexData } from "@/api/index";

export default {
  components: {},
  data() {
    return {};
  },
  mounted() {
    this.getBanner();
  },
  methods: {
    getBanner() {
      indexData({ clientType: "PC" }).then((res) => {
        let data = JSON.parse(res.result.pageData).list;
        console.log(data);
        let list = data.find((item) => item.type === "carousel");
        this.list = list.options.list;
      });
    },
  },
};
</script>
<style scoped lang="scss">
.wrap-recommend-cate {
}
</style>
