<template>
  <div
    @click="click"
    class="wrap-com-btn f-c-c"
    :class="{
      'type-primary': type === 'primary',
      'type-light': type === 'light',
      plain,
      empty,
      fs14,
    }"
    :style="
      useStyle
        ? {
            width: w + 'px',
            height: h + 'px',
          }
        : {}
    "
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "primary", // primary, light
    },
    w: {
      type: [String, Number],
      default: "93", // 宽
    },
    h: {
      type: [String, Number],
      default: "40", // 高
    },
    useStyle: {
      type: Boolean,
      default: true,
    },
    plain: {
      type: Boolean,
      default: false,
    },
    empty: {
      type: Boolean,
      default: false,
    },
    fs14: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    click(e) {
      this.$emit("click", e);
    },
  },
};
</script>
<style scoped lang="scss">
.wrap-com-btn {
  cursor: pointer;
  width: 93px;
  height: 40px;
  border-radius: 2px;
  font-size: 12px;
  box-sizing: border-box;

  &.fs14 {
    font-size: 14px;
  }

  &.type-primary {
    color: #fff;
    background: var(--color-primary);
  }

  &.type-light {
    color: #fff;
    background: var(--color-primary-light);
  }

  &.plain {
    color: var(--color-primary);
    background: #fff;
    border: 1px solid var(--color-primary);
  }

  &.empty {
    border: 1px solid #dddddd;
    background: #fff;
    color: #444444;
  }
}
</style>
