<template>
  <div class="wrap-category">
    <div class="cate">
      <div v-for="item in levelOneMenu" :key="item.id" class="item">
        <div class="title">{{ item.name }}</div>
        <div class="subtitle">{{ item.subtitle }}</div>
      </div>

      <div class="right">
        <div class="title">全部商品分类</div>
        <div class="list">
          <div
            v-for="item in levelOneMenu"
            :key="item.name"
            class="sub-item f-c"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      levelOneMenu: [
        {
          id: 1,
          name: "手机",
          subtitle: "手机手机手机手机",
        },
        {
          id: 2,
          name: "电脑",
          subtitle: "电脑电脑电脑",
        },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped lang="scss">
.wrap-category {
  .cate {
    width: 210px;
    height: 474px;
    background: #ffffff;
    padding: 20px;
    box-sizing: border-box;
    position: relative;

    &:hover .right {
      display: block;
    }

    .item {
      margin-bottom: 12px;
      cursor: pointer;
    }

    .title {
      font-size: 14px;
      color: #444444;
      margin-bottom: 2px;
    }

    .subtitle {
      font-size: 12px;
      color: #999999;
    }
  }

  .right {
    right: -770px;
    top: -2px;
    display: none;
    z-index: 99;
    position: absolute;
    border: 2px solid var(--color-primary);
    padding: 20px;
    box-sizing: border-box;
    width: 770px;
    height: 474px;
    background: #ffffff;
    box-shadow: -1px 0px 4px 0px rgba(153, 153, 153, 0.5);

    .title {
      font-weight: bold;
      font-size: 14px;
      color: var(--color-primary);
      line-height: 19px;
      margin-bottom: 18px;
    }

    .sub-item {
      width: 360px;
      font-size: 14px;
      color: #444444;
      line-height: 19px;
      text-align: left;
      margin-bottom: 8px;
      &:hover {
        color: var(--color-primary);
      }
    }

    .list {
      flex-wrap: wrap;
      display: flex;
    }
  }
}
</style>
