<template>
  <div class="layout-content wrap-recommend-for-you">
    <div class="top f-c-b">
      <div class="title">为您推荐</div>
      <div class="right f-c">
        <div class="cate-wrap f-c-c">
          <div
            v-for="item in cateList"
            :class="{ active: item.id === currentCate }"
            :key="item.id"
            class="item f-c-c"
            @click="selectCate(item.id)"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="more">查看更多 ></div>
      </div>
    </div>
    <div class="list">
      <com-product
        :index="index"
        :item="item"
        v-for="(item, index) in productList"
        :key="item.id"
      />
    </div>
  </div>
</template>

<script>
import { indexData } from "@/api/index";

export default {
  components: {},
  data() {
    return {
      cateList: [
        {
          name: "全部",
          id: 0,
        },
        {
          name: "男装",
          id: 1,
        },
      ],
      currentCate: "",
      productList: [],
    };
  },
  mounted() {
    this.getBanner();
  },
  methods: {
    selectCate(id) {
      this.currentCate = id;
    },
    getBanner() {
      indexData({ clientType: "PC" }).then((res) => {
        let data = JSON.parse(res.result.pageData).list;
        let list = data.find((item) => item.type === "goodsType");
        this.productList = list.options.list;
        console.log(this.productList);
      });
    },
  },
};
</script>
<style scoped lang="scss">
.wrap-recommend-for-you {
  margin-top: 10px;
  .top {
    width: 100%;
    height: 10px;
    height: 70px;
    padding: 0 24px 0 20px;
    box-sizing: border-box;
  }

  .title {
    font-weight: bold;
    font-size: 24px;
    color: var(--color-primary);
  }

  .cate-wrap {
    margin-right: 40px;

    .item {
      width: 134px;
      height: 40px;
      font-size: 18px;
      color: #444444;
      line-height: 24px;
      cursor: pointer;

      &.active {
        color: var(--color-primary);
        text-decoration-line: underline;
      }
    }
  }

  .more {
    font-size: 12px;
    color: var(--color-primary);
    cursor: pointer;
  }

  .list {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
