<template>
  <div class="layout-content wrap-footer">
    <div class="footer-left">
      <div class="ft-lt">投诉及建议</div>
      <div class="ft-lp">400-999-9999</div>
      <div class="ft-lp">service@email.com</div>
      <div class="ft-lp">上午8:00~12:00 下午14:00~18:00</div>
      <div class="ft-lp">（周一至周六（上午）,节假日除外）</div>
      <com-btn>问题反馈</com-btn>
    </div>
    <div class="footer-center">
      <div class="ft-ce" v-for="(items, index) in list" :key="index">
        <div class="ft-ct">{{ items.title }}</div>
        <div class="ft-lp" v-for="item in items.children" :key="item.name">
          <a class="ft-a" @click="goPath(item.path)" href="">
            {{ item.name }}
          </a>
        </div>
      </div>
    </div>
    <div class="footer-right1">
      <img class="img-qr" src="@/assets/img/index-qr.png" alt="" />
      关注官方微信公众号
    </div>
    <div class="footer-right2">
      <img class="img-qr" src="@/assets/img/index-qr.png" alt="" />
      微信小程序
    </div>
    <div class="footer-bot">
      Copyright © 2010-2024 飞鹄一启铖工品××××有限公司 | 版权所有 违者必究
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      list: [
        {
          title: "交易",
          children: [
            {
              name: "交易流程",
              path: "",
            },
            {
              name: "发票须知",
              path: "",
            },
            {
              name: "快速选型",
              path: "",
            },
            {
              name: "如何快速报价",
              path: "",
            },
          ],
        },
        {
          title: "配送",
          children: [
            {
              name: "配色说明",
              path: "",
            },
            {
              name: "配色须知",
              path: "",
            },
            {
              name: "验货签收",
              path: "",
            },
            {
              name: "配送答疑",
              path: "",
            },
          ],
        },
        {
          title: "帮助",
          children: [
            {
              name: "网站地图",
              path: "",
            },
            {
              name: "常见问题",
              path: "",
            },
          ],
        },
        {
          title: "关于",
          children: [
            {
              name: "关于我们",
              path: "",
            },
            {
              name: "联系我们",
              path: "",
            },
          ],
        },
      ],
    };
  },
  mounted() {},
  methods: {
    goPath(path) {
      this.$router.push({ path });
    },
  },
};
</script>
<style scoped lang="scss">
.wrap-footer {
  display: flex;
  flex-wrap: wrap;
  height: 380px;

  margin-top: 50px;

  .ft-lp {
    margin-bottom: 18px;
    font-size: 14px;
    color: #696969;
  }
  .footer-left {
    width: 234px;
    margin-right: 77px;

    .ft-lt {
      margin-bottom: 18px;
      font-size: 24px;
      color: #000000;
      font-weight: bold;
    }
  }
  .footer-center {
    display: flex;
    .ft-ce {
      margin-left: 76px;
    }
    .ft-ct {
      margin-bottom: 18px;
      font-size: 18px;
      color: #000000;
      font-weight: bold;
    }
    .ft-a {
      color: inherit;
      text-decoration: none;
    }
  }
  .footer-right1 {
    margin-left: 68px;
    width: 125px;
    height: 147px;
    background: #ffffff;
    font-size: 12px;
    color: #696969;
    text-align: center;
  }
  .img-qr {
    margin: 9px 10px 6px 13px;
    width: 102px;
    height: 103px;
  }
  .footer-right2 {
    margin-left: 14px;
    width: 125px;
    height: 147px;
    background: #ffffff;
    font-size: 12px;
    color: #696969;
    text-align: center;
  }
  .footer-bot {
    margin: auto;
    font-size: 12px;
    color: #000000;
  }
}
</style>
