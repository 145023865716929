<template>
  <div class="wrap-info-box">
    <template v-if="!token">
      <div class="top-title">Hi，{{ user.name }}</div>
      <div class="f-c">
        <com-btn class="btn-user" @click="goPath('/user/info')">
          个人中心
        </com-btn>
      </div>
    </template>
    <template v-else>
      <div class="top-title">Hi，您好！</div>
      <div class="subtitle">欢迎光临一启铖工品</div>
      <div class="f-c-c">
        <com-btn @click="goPath('/login')" class="btn">登录</com-btn>
        <com-btn @click="goPath('/register')" plain class="btn">注册</com-btn>
      </div>
    </template>

    <div class="card">
      <div v-for="item in cardList" :key="item.title" class="item">
        <img @click="goPath(item.path)" class="icon" :src="item.img" alt="" />
        {{ item.title }}
      </div>
    </div>

    <div class="card-service f-c">
      <div class="left">
        <div class="text1">您的服务对接人：</div>
        <div class="text2">李斯</div>
        <div class="text">13322223333</div>
      </div>
      <img class="img-qr" src="@/assets/img/index-qr.png" alt="" />
    </div>

    <div class="card-info">
      <div class="f-c-b">
        <div class="title">资讯</div>
        <div @click="goPath('/news/info')" class="more">更多 ></div>
      </div>

      <div class="list">
        <div v-for="item in infoList" :key="item.title" class="item ellipsis">
          {{ item.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getToken } from "@/utils/cookies";

export default {
  components: {},
  data() {
    return {
      token: "",
      user: {},
      cardList: [
        {
          img: require("@/assets/img/index-quote.png"),
          title: "报价单管理",
          path: "/user/myQuote",
        },
        {
          img: require("@/assets/img/index-quote.png"),
          title: "订单管理",
          path: "/user/myOrder",
        },
        {
          img: require("@/assets/img/index-quote.png"),
          title: "发票管理",
          path: "/user/myInvoice",
        },
        {
          img: require("@/assets/img/index-quote.png"),
          title: "售后管理",
          path: "/user/myAfterSale",
        },
      ],
      infoList: [
        {
          title: "北京在京津冀协同2",
          content: "1",
        },
        {
          title: "北京在京津冀协同发展北京在京津冀协同发展中承载…4",
          content: "1",
        },
        {
          title: "北京在京津冀协同发展6",
          content: "1",
        },
      ],
    };
  },
  mounted() {
    this.token = getToken();
  },
  methods: {
    goPath(path) {
      this.$router.push({
        path,
      });
    },
  },
};
</script>
<style scoped lang="scss">
.wrap-info-box {
  width: 210px;
  height: 474px;
  background: #ffffff;
  padding: 16px 12px;
  box-sizing: border-box;

  .top-title {
    font-weight: bold;
    font-size: 14px;
    color: #444444;
    margin-bottom: 3px;
  }

  .subtitle {
    font-size: 14px;
    color: #696969;
    margin-bottom: 17px;
  }

  .btn-user {
    margin-top: 16px;
    margin-bottom: 10px;
  }

  .btn {
    width: 74px;
    height: 32px;
    margin-right: 14px;
  }

  .card {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 4px 0;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    margin-top: 16px;

    .item {
      width: 93px;
      height: 60px;
      font-size: 12px;
      color: #444444;
      text-align: center;
      cursor: pointer;
    }

    .icon {
      width: 34px;
      height: 34px;
      margin-bottom: 2px;
      margin: 0 auto;
    }
  }

  .card-service {
    font-size: 12px;
    color: #444444;
    padding-top: 7px;
    padding-bottom: 7px;
    border-bottom: 1px solid #dddddd;
    align-items: flex-start;

    .text1 {
      color: #696969;
      margin-top: 4px;
    }

    .text2 {
      margin-top: 6px;
      margin-bottom: 2px;
    }

    .img-qr {
      width: 65px;
      height: 65px;
    }
  }

  .card-info {
    width: 186px;
    height: 120px;
    background: #fdf8f1;
    border-radius: 2px;
    padding: 8px;
    box-sizing: border-box;
    margin-top: 13px;

    .title {
      font-weight: bold;
      font-size: 12px;
      color: #444444;
      line-height: 16px;
    }

    .more {
      font-size: 12px;
      color: var(--color-primary);
      cursor: pointer;
    }

    .list {
      margin-top: 14px;
    }

    .item {
      font-size: 12px;
      line-height: 16px;
      color: #444444;
      margin-bottom: 6px;
    }
  }
}
</style>
